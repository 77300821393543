import React from 'react'
import { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import classnames from 'classnames'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo'
import Hero from '../../../components/cloud-studio/hero'
import StickyCta from '../../../components/cloud-studio/sticky-cta'
import Card from '../../../components/cloud-studio/card'
import CardIcon from '../../../components/cloud-studio/card-icon'
import CardFullWidthImage from '../../../components/cloud-studio/card-full-width-image'
import SectionTitle from '../../../components/cloud-studio/section-title'
import Accordion from '../../../components/cloud-studio/accordion'
import Table from '../../../components/cloud-studio/table'
import Button from '../../../components/cloud-studio/button'
import Chip from '../../../components/cloud-studio/chip'
import { Tabs, TabContent } from '../../../components/cloud-studio/tabs'

// Assets
import Scaling from '../../../images/cloud-studio/scaling.inline.svg'
import Speed from '../../../images/cloud-studio/speed.inline.svg'
import Cost from '../../../images/cloud-studio/cost.inline.svg'
import Launch from '../../../images/cloud-studio/launch.inline.svg'
import IconRightArrow from '../../../images/arrow-right-alt.inline.svg'

import { removeDecimalIfWhole } from '../../../utils/removeDecimalIfWhole'

const PRICING_REGION = 0
const HOURLY_PRICE_PRECISION = 3
const CATEGORY_DISPLAY_ORDER = ['gp', 'mem-opt', 'cpu-opt']

const formatProductDetailsForDisplay = detailsForCart => {
  const formatConfigDetails = ({ id, cores, memory, disk }) => {
    const regionData = detailsForCart.regions[PRICING_REGION].configs.find(
      regionConfig => regionConfig.id === id
    )

    const monthlyPriceRaw = regionData.price.find(
      price => price.unit === 'month'
    ).amount

    // Would like to use the trailingZeroDisplay option on
    // the currency formatter here, but it landed in Firefox
    // just a few months ago as of this commit. Soon.
    const monthlyPrice = `$${Intl.NumberFormat('en-US').format(
      removeDecimalIfWhole(monthlyPriceRaw)
    )}`

    const hourlyPrice = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: HOURLY_PRICE_PRECISION,
      maximumFractionDigits: HOURLY_PRICE_PRECISION,
    }).format(regionData.price.find(price => price.unit === 'hour').amount)

    return {
      id,
      cores,
      memory: `${memory} GB`,
      disk: `${Intl.NumberFormat('en-US').format(disk)} GB`,
      bandwidth: `${Intl.NumberFormat('en-US').format(
        regionData.bandwidth
      )} GB`,
      monthlyPrice: monthlyPrice,
      hourlyPrice: hourlyPrice,
    }
  }

  return detailsForCart.hardware
    .map(category => ({
      ...category,
      configs: category.configs.map(config => formatConfigDetails(config)),
    }))
    .sort(
      (a, b) =>
        CATEGORY_DISPLAY_ORDER.indexOf(a.category) -
        CATEGORY_DISPLAY_ORDER.indexOf(b.category)
    )
}

function CloudVPSPage({ data }) {
  const detailsForDisplay = useMemo(
    () => formatProductDetailsForDisplay(data.lwCloudServerDetailsForCart),
    [data]
  )

  return (
    <Layout
      mainClass={'bg-[#0D0E11] font-roobert'}
      headerVariant={'dark'}
      footerVariant={'dark'}
    >
      <Seo
        post={{
          seo: {
            title: 'Cloud VPS Hosting | Liquid Web Cloud Studio',
            metaDesc:
              'Cloud VPS by Cloud Studio offers the perfect blend of power and affordability. Resize with one click, and pay as little as $5 a month. Explore now.',
          },
        }}
      />
      <Hero
        label="CLOUD VPS HOSTING"
        title="Cloud VPS: Your resources, your software,"
        rotatingTitles={[' your choice']}
        subtitle="Flexible, instant cloud environments that can be scaled up or down to meet your needs."
        image={data.cloudVPSHeroImage}
        imagePosition="left"
      />

      <StickyCta
        title="Build a Cloud VPS slice, starting at $5 a month"
        url="/configuration/cloud-studio/?type=vps"
      />

      <div className="container mb-32 relative z-10">
        <h2
          className={classnames(
            'text-base',
            'text-white',
            'font-normal',
            'uppercase',
            'mt-0',
            'mb-6',
            'tracking-[0.8px]'
          )}
        >
          Cloud Vps Benefits
        </h2>
        <div className="grid gap-12 lg:grid-cols-3">
          <CardIcon
            icon={<Scaling />}
            content="Scaling. Easily adjust your resources to handle increased traffic during holidays and promotions."
          />
          <CardIcon
            icon={<Speed />}
            content="Streamlining. Our API and integration tools allow your growth to be automated and seamless."
          />
          <CardIcon
            icon={<Cost />}
            content="Cost. Incremental pricing ensures your hosting expenses grow steadily—no surprise bills here."
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Cloud VPS plans" />
        <Tabs>
          {detailsForDisplay.map(category => (
            <TabContent key={category.category} title={category.description}>
              <Table>
                <tbody>
                  <tr className="uppercase tracking-widest whitespace-nowrap">
                    <td>CPU</td>
                    <td align="center">Memory</td>
                    <td align="center">Storage</td>
                    <td align="center">Transfer</td>
                    <td align="center">Monthly Cost</td>
                    <td align="center">Hourly Cost</td>
                    <td></td>
                  </tr>
                  {category.configs.map(config => (
                    <tr key={config.id}>
                      <td className="font-bold">{config.cores}</td>
                      <td align="center">{config.memory}</td>
                      <td align="center">{config.disk}</td>
                      <td align="center">{config.bandwidth}</td>
                      <td align="center">
                        <Chip label={config.monthlyPrice} />
                      </td>
                      <td align="center" className="text-[#959595]">
                        {config.hourlyPrice}
                      </td>
                      <td>
                        <Button
                          className={classnames(
                            '!text-base',
                            'whitespace-nowrap'
                          )}
                          text="Build Now"
                          icon={<IconRightArrow />}
                          url="/configuration/cloud-studio/?type=vps"
                          variant="plain"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabContent>
          ))}
        </Tabs>
      </div>

      <div className="container mb-32">
        <SectionTitle
          title="A platform as nimble as you are"
          className="max-w-full"
        />
        <div className="grid gap-y-10 lg:gap-x-10 lg:grid-cols-12">
          <Card
            className="col-span-12 lg:col-span-5"
            variant="dark"
            label="Computing"
            title="Get the power you need without the hassle"
            description="Scale your resources up or down any time—without the pain of moving between solutions."
          />
          <Card
            className="col-span-12 lg:col-span-7"
            variant="dark"
            label="Fast provisioning"
            title="Launch fast"
            description="Quick provisioning ensures your Cloud VPS is ready for action within minutes of buying."
            icon={<Launch />}
          />
          <CardFullWidthImage
            className="col-span-12"
            variant="dark"
            label="Process"
            title="Automate your workflows"
            description="Plans with gradual resource increases and powerful integrations are a great match for a variety of workflows."
            image={data.deliverIdeasImage}
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Rock-solid cloud VPS hosting" />
        <div className="grid gap-10 lg:grid-cols-2">
          <Card
            variant="light"
            label="Guarantee"
            title="High availability"
            description="Our network and power uptime SLA ensure that all major routing devices in our network are reachable 100% of the time."
          />
          <Card
            variant="dark"
            label="Safeguards"
            title="Peace of mind"
            description="Backups and disaster recovery solutions let you protect your data and restore your server after a catastrophic event."
          />
        </div>
      </div>

      <div className="container mb-32">
        <SectionTitle title="Cloud VPS FAQs" />
        <Accordion
          className="border-t border-[#3A3A3A]"
          title="What is Cloud VPS?"
        >
          <p>
            Cloud VPS consists of a virtual private server (VPS) on scalable
            cloud infrastructure. At Liquid Web, Cloud VPS runs on our{' '}
            <Link to="/products/cloud-hosting/">Cloud Studio platform</Link>—an
            elastic solution that lets you scale between products as your needs
            change. Our cloud VPS solutions have granular and scalable
            resources.
          </p>
        </Accordion>
        <Accordion title="Which is better: Fully managed VPS hosting or Cloud VPS hosting?">
          <p>
            It depends on your needs and goals! Fully managed VPS hosting comes
            in a pre-bundled offering with backups, control panels, and other
            important features included. By contrast, Cloud VPS customers only
            opt into the features they want. If you know exactly what you need,
            Cloud VPS empowers you to purchase it—no more, no less.{' '}
            <Link to="/contact-us/">Contact us now</Link> to learn which VPS
            solution is best for you.
          </p>
        </Accordion>
        <Accordion title="Is Cloud VPS managed or unmanaged?">
          <p>
            Cloud VPS is designed to be a powerful unmanaged solution, but you
            can opt into management services if you’d like.
          </p>
        </Accordion>
        <Accordion title="How can I get started">
          <p>
            To get started, click the “Build” banner and choose which features
            and resources you’d like. If you’re not sure, you can always start
            with a VPS “slice” and resize as your needs change.
          </p>
        </Accordion>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query VPSPageQuery {
    cloudVPSHeroImage: file(
      relativePath: { eq: "cloud-studio/cloud-vps-hero.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: CONSTRAINED
          transformOptions: { fit: CONTAIN }
        )
      }
    }

    deliverIdeasImage: file(
      relativePath: { eq: "cloud-studio/deliver-ideas.png" }
    ) {
      name
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: CONSTRAINED
          transformOptions: { fit: CONTAIN }
        )
      }
    }

    lwCloudServerDetailsForCart(product_code: { eq: "Cloud.VPS" }) {
      hardware {
        category
        description
        configs {
          id
          cores
          disk
          memory
        }
      }
      regions {
        configs {
          id
          bandwidth
          price {
            amount
            unit
          }
        }
      }
    }
  }
`

export default CloudVPSPage
