import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'
import ArrowRight from '../../images/cloud-studio/arrow-right.inline.svg'

function StickyCta({ className, title, url }) {
  return (
    <div
      className={classnames(
        'w-full',
        'left-1/2',
        'bottom-12',
        'transform',
        '-translate-x-1/2',
        'max-w-screen-2xl',
        'px-4',
        'fixed',
        'z-20',
        'lg:bottom-16'
      )}
    >
      <Link
        to={url}
        className={classnames(
          'block',
          'rounded-lg',
          'py-3',
          'px-6',
          'transition-background-color',
          'duration-500',
          'ease-in-out',
          'bg-lw-text-blue',
          'hover:bg-[#1A61CD]',
          'lg:py-6',
          'lg:px-8',
          className
        )}
      >
        <div className="flex items-center justify-between">
          <div
            className={classnames(
              'text-xl',
              'text-white',
              'font-light',
              'leading-[110%]',
              'tracking-[-0.96px]',
              'lg:text-2xl'
            )}
          >
            {title}
          </div>
          <ArrowRight />
        </div>
      </Link>
    </div>
  )
}

export default StickyCta
